<template>
    <b-alert
        variant="info"
        show
        class="mb-0 border-0 rounded-0 text-center py-5"
    >
        <b-container>
            <div class="d-flex align-items-center justify-content-around mt-3">
                <div>
                    <h1 class="mb-0">Document Verification</h1>
                </div>
            </div>

            <div class="d-flex justify-content-around my-5">
                <b-btn variant="danger" @click="reject" size="lg" class="px-5">
                    Reject
                </b-btn>
                <b-btn
                    variant="primary"
                    @click="approve"
                    size="lg"
                    class="px-5"
                >
                    Approve
                </b-btn>
            </div>

            <div
                :key="userDocument.id"
                v-for="userDocument in userDocuments"
                class="border bg-light p-3 my-3"
            >
                <h3 class="text-center p-3">
                    {{ userDocument.type }}
                </h3>
                <b-img fluid :src="userDocument.signed" />
            </div>
        </b-container>

        <b-button
            v-b-toggle.newBuyerTraining
            variant="info"
            size="sm"
            class="mt-3"
        >
            <font-awesome-icon icon="fa-duotone fa-info-circle" />
            Training
        </b-button>
        <b-collapse id="newBuyerTraining">
            <div class="text-center mt-3">
                Ensure the documents are legitimate. Expect to see some fakes,
                some users may be able to use AI or other modern tools to
                generate very realistic looking fakes. Try to find an ID guide
                online for the specific ID type online
            </div>
        </b-collapse>
    </b-alert>
</template>



<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            buyerRisks: null,
            userDocuments: null,
        };
    },
    mounted() {
        this.refresh();
    },
    computed: {
        userDocumentIds() {
            return this.userDocuments.map((userDocument) => userDocument.id);
        },
        ...mapState("userEdit", ["user"]),
    },
    methods: {
        reject() {
            this.api
                .post(`/document/${this.user.model.id}/reject`, {
                    userDocumentIds: this.userDocumentIds,
                })
                .then(() => {
                    this.refresh();
                });
        },
        approve() {
            this.api
                .post(`/document/${this.user.model.id}/approve`, {
                    userDocumentIds: this.userDocumentIds,
                })
                .then(() => {
                    this.refresh();
                });
        },
        acceptRisk(risk) {
            this.accepted.push(risk.id);
        },
        refresh() {
            this.api
                .post(`/document/${this.user.model.id}/index`)
                .then((response) => {
                    this.userDocuments = response.data.userDocuments;

                    if (!this.userDocuments.length) {
                        this.$router.push("/document");
                    }
                });
        },
        async screen(buyerRisk) {
            await this.api.post(`/buyerRisk/${buyerRisk.id}/screen`);
            this.refresh();
        },
    },
};
</script>