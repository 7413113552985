<template>
    <div>
        <b-dropdown id="dropdown-1" text="Dropdown Button" class="m-md-2">
            <b-dropdown-item :key="country.id" v-for="country in countries">
                {{ country.name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>


<script>
import { mapState } from "vuex";
export default {
    mounted() {
        this.$store.dispatch("userFilters/loadOnce");
    },
    computed: {
        ...mapState("userFilters", [
            "loaded",
            "countries",
            "metas",
            "landings",
            "sources",
            "tiers",
            "filtersEnabled",
            "statuses",
        ]),
    },
};
</script>