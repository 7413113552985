<template>
    <div>
        <b-table-simple class="small" striped>
            <b-thead>
                <tr>
                    <th></th>
                    <th>Location</th>
                    <th>IP</th>
                    <th>Status</th>
                    <th>Last Usage</th>
                </tr>
            </b-thead>
            <b-tbody>
                <b-tr :key="ip.ipAddress.id" v-for="ip in ipAddresses">
                    <td>
                        <div v-if="ip.ipAddress.city">
                            <b-btn
                                @click="setCity(ip.ipAddress.city)"
                                size="sm"
                                variant="light"
                                class="text-truncate"
                            >
                                <font-awesome-icon
                                    icon="fa-duotone fa-map-marker"
                                />
                                Set Location
                            </b-btn>
                        </div>
                    </td>
                    <td>
                        <div v-if="ip.ipAddress.city">
                            <img :src="ip.ipAddress.country?.flagUrl" />
                            {{ ip.ipAddress.city.name }},
                            {{ ip.ipAddress.country.name }}
                        </div>
                        <div v-else class="text-danger">
                            <font-awesome-icon
                                icon="fa-duotone fa-triangle-exclamation"
                            />
                            Missing Location
                        </div>
                    </td>
                    <td>
                        {{ ip.ipAddress.ipAddress }}
                    </td>
                    <td :class="{ 'text-danger': ip.ipAddress.bannedOrVpn }">
                        {{ ip.ipAddress.status }}
                    </td>
                    <td>
                        {{ ip.lastUsage }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            ipAddresses: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        async setCity(city) {
            await this.api.post(`/user/${this.user.model.id}/city/${city.id}`);

            this.$store.dispatch("userEdit/refresh");
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/ipAddresses`)
                .then((response) => {
                    this.ipAddresses = response.data.ipAddresses;
                });
        },
    },
};
</script>