<template>
    <b-container>
        <b-alert v-if="trainingWheels" variant="info" show class="mt-3">
            These photos have already been processed through both Google and
            Amazon, and passed moderation. This is a quick double check to make
            sure their AI did not miss anything, which it sometimes does. The
            main thing that causes it to miss body parts is when they are sent
            from a weird angle or partially obscured, so be on the lookout for
            that.

            <div class="mt-3">When to reject?</div>

            <ul>
                <li>
                    Explicit Content - any type of sexual content, or private
                    parts showing must be rejected. Swimwear is OK unless its
                    sexual in nature/suggestive, or reveals private parts
                    through the clothing
                </li>
                <li>
                    Minors - anyone suspected of being underage in the photo.
                    Even if its their child, reject any photos containing minors
                </li>
            </ul>

            <div class="mt-3">
                Once you have reviewed all photos, click All Photos OK at the
                bottom of the screen to mark all of the photos screened
            </div>
        </b-alert>

        <b-row>
            <b-col
                cols="4"
                :key="userPhoto.id"
                v-for="userPhoto in unscreenedUserPhotos"
            >
                <a :href="userPhoto.large" target="_blank">
                    <div class="border rounded my-3">
                        <b-btn
                            @click="reject(userPhoto)"
                            class="rounded-0 btn-danger"
                            block
                        >
                            <i class="fa-duotone fa-thumbs-down"></i>
                            Reject
                        </b-btn>
                        <b-img fluid :src="userPhoto.large" />
                    </div>
                </a>
            </b-col>
        </b-row>

        <div class="text-center p-5 bg-dark rounded">
            <b-btn @click="markScreened" variant="light"> All Photos OK </b-btn>
        </div>
    </b-container>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            pauseScreening: false,
            userPhotos: null,
            screened: [],
        };
    },
    computed: {
        unscreenedUserPhotos() {
            return this.userPhotos?.filter(
                (userPhoto) => !this.screened.includes(userPhoto.id)
            );
        },
        ...mapState("user", ["trainingWheels"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        markScreened() {
            this.api
                .post("/photo/screened", {
                    userPhotoIds: this.unscreenedUserPhotos.map(
                        (userPhoto) => userPhoto.id
                    ),
                })
                .then(() => {
                    this.$scrollToTop();
                    this.refresh();
                });
        },
        refresh() {
            this.api.post("/photo/index").then((response) => {
                this.userPhotos = response.data.userPhotos;
            });
        },
        reject(userPhoto) {
            this.screened.push(userPhoto.id);
            this.api.post(`/photo/${userPhoto.id}/reject`);
        },
    },
    components: {},
};
</script> 