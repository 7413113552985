<template>
    <generic-page>
        <b-container>
            <div v-if="authorizeTransaction" class="mt-3">
                <div
                    class="d-flex align-items-center justify-content-between mt-3"
                >
                    <h1>
                        Authorize Transaction ID
                        {{ authorizeTransaction.id }}
                    </h1>

                    <div></div>
                </div>

                <b-table-simple class="small" striped>
                    <b-tbody>
                        <b-tr>
                            <b-th> ID </b-th>
                            <b-td>
                                {{ authorizeTransaction.id }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th> Success </b-th>
                            <b-td>
                                <div v-if="authorizeTransaction.successful">
                                    <font-awesome-icon
                                        icon="fa-light fa-check-circle"
                                        class="text-success"
                                    />
                                </div>
                                <div v-else>
                                    <font-awesome-icon
                                        icon="fa-solid fa-times"
                                        class="text-danger"
                                    />
                                    {{ authorizeTransaction.errorMessage }}
                                </div>
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th> Amount </b-th>
                            <b-td> ${{ authorizeTransaction.amount }} </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th> AVS </b-th>
                            <b-td>
                                {{ authorizeTransaction.avsResponse }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th> Postal Code </b-th>
                            <b-td>
                                {{ authorizeTransaction.postalCode }}
                            </b-td>
                        </b-tr>
                        <b-tr>
                            <b-th> Created </b-th>
                            <b-td>
                                <live-timestamp
                                    :timestamp="authorizeTransaction.created"
                                />
                            </b-td>
                        </b-tr>
                    </b-tbody>
                </b-table-simple>

                <b-btn
                    :disabled="saving"
                    @click="blacklist"
                    size="lg"
                    class="mt-3"
                    block
                    variant="danger"
                >
                    Blacklist
                </b-btn>
            </div>
            <div v-else>
                <generic-loading />
            </div>
        </b-container>
    </generic-page>
</template>


<script>
export default {
    data() {
        return {
            authorizeTransaction: null,
            userCreditCard: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/authorizeTransaction/${this.$route.params.id}/get`)
                .then((response) => {
                    this.authorizeTransaction =
                        response?.data?.authorizeTransaction;
                    this.userCreditCard = response?.data?.userCreditCard;
                });
        },
        blacklist() {
            this.$router.push({
                name: "blacklistCreate",
                query: {
                    lastFour: this.userCreditCard.lastFour,
                    postalCode: this.authorizeTransaction.postalCode,
                },
            });
        },
    },
};
</script>