<template>
    <div>
        <b-table-simple striped>
            <b-thead>
                <b-th> User </b-th>
                <b-th> Spend </b-th>
                <b-th> Created </b-th>
            </b-thead>
            <b-tbody>
                <b-tr :key="invite.user.model.id" v-for="invite in invites">
                    <td>
                        <user-avatar :user="invite.user" />
                    </td>
                    <td>
                        {{ invite.creditRefillTotal }}
                    </td>
                    <td>
                        {{ invite.createdRelative }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            invites: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/invites`)
                .then((response) => {
                    this.invites = response.data.invites;
                });
        },
    },
    components: {},
};
</script>