<template>
    <div>
        <div v-if="filteredVideos.length">
            <h6>Videos</h6>

            <b-row no>
                <b-col
                    md="4"
                    cols="12"
                    :key="video.id"
                    v-for="video in filteredVideos"
                >
                    <div class="rounded border mb-5">
                        <div
                            class="small p-2 bg-light d-flex justify-content-between"
                        >
                            <live-timestamp :timestamp="video.created" />

                            <div v-if="video.seen">
                                <font-awesome-icon
                                    icon="fa-duotone fa-eye"
                                    class="text-primary"
                                />
                            </div>

                            <div v-if="isPrimary(video)">
                                <font-awesome-icon
                                    icon="fa-duotone fa-star"
                                    class="text-warning"
                                />
                            </div>

                            {{ video.status }}
                            <b-btn
                                v-if="video.stuck"
                                variant="light"
                                @click="deleteVideo(video)"
                                size="sm"
                            >
                                <font-awesome-icon icon="fa-duotone fa-trash" />
                                Stuck?
                            </b-btn>
                        </div>

                        <b-overlay :show="video.unprocessed" blur>
                            <b-btn-group class="w-100">
                                <b-btn
                                    variant="light"
                                    class="text-danger py-2"
                                    v-if="video.approved || video.pending"
                                    @click="reject(video)"
                                    size="sm"
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-envelope"
                                    />
                                    Reject
                                </b-btn>
                                <b-btn
                                    variant="light"
                                    v-if="!isPrimary(video)"
                                    @click="setPrimary(video)"
                                    size="sm"
                                    class="py-2"
                                >
                                    Set Primary
                                </b-btn>
                                <b-btn
                                    variant="light"
                                    v-if="!video.approved"
                                    @click="approve(video)"
                                    size="sm"
                                    class="py-2"
                                >
                                    Approve
                                </b-btn>
                                <b-btn
                                    v-if="video.deleted"
                                    variant="light"
                                    @click="restoreVideo(video)"
                                    size="sm"
                                    class="py-2 text-danger"
                                >
                                    Restore
                                </b-btn>
                                <b-btn
                                    v-else
                                    variant="light"
                                    @click="deleteVideo(video)"
                                    size="sm"
                                    class="py-2"
                                >
                                    <font-awesome-icon
                                        icon="fa-duotone fa-trash"
                                    />
                                </b-btn>
                                <b-btn
                                    variant="light"
                                    v-if="video.seen"
                                    @click="unseen(video)"
                                    size="sm"
                                    class="py-2"
                                >
                                    Unseen
                                </b-btn>

                                <b-btn
                                    v-else
                                    variant="light"
                                    @click="seen(video)"
                                    size="sm"
                                    class="py-0"
                                >
                                    <font-awesome-icon
                                        icon="fa-light fa-check"
                                    />
                                    Seen
                                </b-btn>
                            </b-btn-group>
                        </b-overlay>

                        <div>
                            <video
                                controls
                                class="img-fluid m-0 p-0"
                                :src="video.webmUrl"
                            ></video>
                        </div>

                        <b-alert
                            variant="danger"
                            v-if="video.rejectReason"
                            show
                            class="mb-0 rounded-0 border-0"
                        >
                            {{ video.rejectReason }}
                        </b-alert>

                        <b-table-simple borderless class="mb-0">
                            <b-thead>
                                <b-tr>
                                    <b-th style="width: 1%" class="bg-light">
                                        Code
                                    </b-th>
                                    <b-th>Video Transcript </b-th>
                                </b-tr>
                            </b-thead>
                            <b-tbody>
                                <b-tr>
                                    <b-td class="bg-light">
                                        <code>
                                            {{ video.code }}
                                        </code>
                                    </b-td>
                                    <b-td> "{{ video.transcript }}" </b-td>
                                </b-tr>
                            </b-tbody>
                        </b-table-simple>

                        <div v-if="$route.query?.vetting" class="mt-3">
                            <b-btn
                                :disabled="savingVetted"
                                variant="success"
                                @click="vetted(video)"
                                block
                            >
                                <font-awesome-icon
                                    v-if="!user.flags.vettingSentActivation"
                                    icon="fa-duotone fa-envelope"
                                />

                                Vetted
                            </b-btn>
                        </div>
                    </div>
                </b-col>
            </b-row>
        </div>
        <div v-else-if="vettingInProgress">
            <b-alert variant="warning" show>
                <h5>No pending videos</h5>
                You can continue on to the next user, or change to the videos
                tab to view seen/moderated videos

                <div class="mt-3">
                    <b-btn class="mr-3" variant="primary" to="/vetting">
                        Next User
                    </b-btn>
                    <b-btn variant="light" @click="videosTab">
                        All Videos
                        <b-badge variant="dark">
                            {{ user.videos.length }}
                        </b-badge>
                    </b-btn>
                </div>
            </b-alert>
        </div>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            savingVetted: false,
            locallyRejectedVideoIds: [],
        };
    },
    computed: {
        vettingInProgress() {
            return this.$route.query?.vetting;
        },
        filteredVideos() {
            if (this.vettingMode) {
                return this.user.videos.filter(
                    (video) =>
                        !video.seen &&
                        !video.deleted &&
                        !this.locallyRejectedVideoIds.includes(video.id)
                );
            }

            return this.user.videos;
        },
        ...mapState("userEdit", ["user"]),
        ...mapState("ninja", ["unblurredUserIds"]),
        ...mapState("user", ["trainingWheels"]),
    },
    methods: {
        async vetted(userVideo) {
            this.savingVetted = true;
            await this.api.post(`/vetting/${userVideo.id}/video`);
            this.savingVetted = false;
            this.$router.push("/vetting");
        },
        videosTab() {
            this.$store.dispatch("userEdit/changeTab", {
                tab: "videos",
                route: this.$route,
            });
        },
        isPrimary(video) {
            return this.user.model.primaryVideoId === video.id;
        },
        async restoreVideo(video) {
            this.locallyRejectedVideoIds.push(video.id);
            await this.api.post(`/video/restore`, {
                userVideoId: video.id,
            });
            this.$store.dispatch("userEdit/refresh");
        },
        async deleteVideo(video) {
            this.locallyRejectedVideoIds.push(video.id);
            await this.api.post(`/video/${video.id}/delete`);
            this.$store.dispatch("userEdit/refresh");
        },
        async reject(video) {
            this.locallyRejectedVideoIds.push(video.id);
            await this.api.post(`/video/${video.id}/reject`);
            this.$store.dispatch("userEdit/refresh");
        },
        async approve(video) {
            await this.api.post(`/video/${video.id}/approve`);
            this.$store.dispatch("userEdit/refresh");
        },
        async seen(video) {
            this.locallyRejectedVideoIds.push(video.id);
            await this.api.post(`/video/${video.id}/seen`);
            this.$store.dispatch("userEdit/refresh");
        },
        async unseen(video) {
            this.locallyRejectedVideoIds.push(video.id);
            await this.api.post(`/video/${video.id}/unseen`);
            this.$store.dispatch("userEdit/refresh");
        },
        async setPrimary(video) {
            await this.api.post(`/video/${video.id}/setPrimary`);
            this.$store.dispatch("userEdit/refresh");
        },
    },
    components: {},
    props: ["vettingMode"],
};
</script>