<template>
    <div>
        <b-table-simple class="small" striped>
            <b-tbody>
                <b-tr>
                    <b-th> ID </b-th>
                    <b-td>
                        {{ authorizeTransaction.id }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Success </b-th>
                    <b-td>
                        <div v-if="authorizeTransaction.successful">
                            <font-awesome-icon
                                icon="fa-light fa-check-circle"
                                class="text-success"
                            />
                        </div>
                        <div v-else>
                            <font-awesome-icon
                                icon="fa-solid fa-times"
                                class="text-danger"
                            />
                            {{ authorizeTransaction.errorMessage }}
                        </div>
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Amount </b-th>
                    <b-td> ${{ authorizeTransaction.amount }} </b-td>
                </b-tr>
                <b-tr>
                    <b-th> AVS </b-th>
                    <b-td>
                        {{ authorizeTransaction.avsResponse }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Postal Code </b-th>
                    <b-td>
                        {{ authorizeTransaction.postalCode }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th> Created </b-th>
                    <b-td>
                        <live-timestamp
                            :timestamp="authorizeTransaction.created"
                        />
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
export default {
    data() {
        return {};
    },
    mounted() {
        // this.refresh();
    },
    methods: {
        async monitorFlag(flag) {
            await this.api.post(`/flag/${flag.id}/monitor`);
            this.refresh();
        },
        async ignoreFlag(flag) {
            await this.api.post(`/flag/${flag.id}/ignore`);
            this.refresh();
        },
        editUser(user) {
            this.$router.push(`/user/edit/${user.id}`);
        },
        refresh() {
            this.api.post(`/flag/${this.flagId}/edit`).then((response) => {
                this.flag = response.data.flag;
                this.userMessages = response.data.userMessages;
            });
        },
    },
    components: {},
    props: ["authorizeTransaction"],
};
</script>