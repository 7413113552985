<template>
    <div>
        Under construction...
        <user-filters />
        <b-table-simple>
            <b-thead>
                <b-tr>
                    <b-th> ID </b-th>
                </b-tr>
            </b-thead>
            <b-tbody>
                <b-tr
                    @click="editUser(user)"
                    :key="user.id"
                    v-for="user in users"
                >
                    <td>
                        {{ user.id }}
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>
    </div>
</template>


<script>
import UserFilters from "@/components/user/UserFilters";

export default {
    data() {
        return {
            users: null,
        };
    },
    mounted() {
        this.refresh();
    },
    methods: {
        editUser(user) {
            this.$router.push(`/user/edit/${user.id}`);
        },
        refresh() {
            this.api.post("/user/index").then((response) => {
                this.users = response.data.users;
            });
        },
    },
    components: {
        UserFilters,
    },
};
</script>