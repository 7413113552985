<template>
    <div>
        <div v-if="refreshing">
            <generic-loading />
        </div>
        <div v-else>
            <div
                :class="{ 'text-primary': isNew(log) }"
                :key="log.id"
                v-for="log in logs"
                class="fake-link p-2 small border-top"
            >
                <span
                    class="float-right font-weight-bold text-center ml-2"
                    style="width: 32px"
                >
                    {{ log.createdRelative }}
                </span>

                <span class="circle-item" v-if="log?.user?.firstLetterOfName">
                    {{ log.user.firstLetterOfName }}
                </span>
                {{ log.log }}
            </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            refreshing: false,
            created: new Date(),
            logs: null,
            actionSubscription: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        // only set true on initial load
        // since it can be refreshed on current user
        this.refreshing = true;
        this.refresh();

        this.actionSubscription = this.$store.subscribeAction((action) => {
            if (action.type === "userEdit/refresh") {
                this.refresh();
            }
        });
    },
    beforeDestroy() {
        if (this.actionSubscription) {
            this.actionSubscription();
        }
    },
    methods: {
        isNew(log) {
            return new Date(log.created) > this.created;
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/logs`)
                .then((response) => {
                    this.logs = response.data.logs;
                    this.refreshing = false;
                });
        },
    },
};
</script>