<template>
    <div>
        <h3 class="text-primary">
            <i class="fa-brands fa-paypal"></i>
            PayPal
        </h3>
        <div class="my-3">
            <code>{{ withdraw.user.email }}</code>
        </div>
        <button
            class="btn btn-primary btn-lg w-100"
            @click="copyText(withdraw.user.email)"
        >
            Copy
        </button>
    </div>
</template>


<script>
import { mapState } from "vuex";

export default {
    computed: {
        ...mapState("withdraw", ["withdraw"]),
    },
};
</script>