<template>
    <b-navbar
        toggleable="sm"
        type="light"
        variant="light"
        fixed="top"
        class="border-bottom"
        style="height: 50px"
    >
        <b-navbar-brand to="/">Ninja</b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav>
                <b-nav-item to="/user/index">Users</b-nav-item>
                <b-nav-item v-if="isRoot" to="/flag/index">Flags</b-nav-item>
                <b-nav-item v-if="isRoot" to="/keyword/index">
                    Keywords
                </b-nav-item>
                <b-nav-item v-if="isRoot" to="/blacklist/index">
                    Blacklist
                </b-nav-item>
                <b-nav-item v-if="isRoot" to="/withdraw/index">
                    Withdrawals
                </b-nav-item>
                <b-nav-item v-if="isRoot" to="/verification/index">
                    Fix Verifications
                </b-nav-item>
            </b-navbar-nav>

            <!-- Right aligned nav items -->
            <b-navbar-nav class="ml-auto">
                <b-navbar-nav>
                    <b-nav-item to="/history">History</b-nav-item>
                </b-navbar-nav>

                <b-nav-form @submit.stop.prevent="search">
                    <b-input-group size="sm">
                        <b-form-input
                            v-model="keyword"
                            placeholder="ID, Name, Email, UUID"
                        ></b-form-input>
                        <b-input-group-append>
                            <b-btn type="submit" variant="secondary">
                                Search
                            </b-btn>
                        </b-input-group-append>
                    </b-input-group>
                </b-nav-form>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
import { mapState } from "vuex";

export default {
    data() {
        return {
            keyword: null,
        };
    },
    computed: {
        ...mapState("user", ["isRoot"]),
    },
    methods: {
        search() {
            this.$router.push(`/search?keyword=${this.keyword}`);
        },
        logout() {
            this.$store.dispatch("user/logout");
        },
    },
};
</script>