<template>
    <div>
        <b-table-simple class="userFields small mb-0" borderless>
            <b-tbody>
                <user-field-brand />
                <user-field-last-active />

                <b-tr v-if="fields.inviteUser">
                    <b-th class="bg-light"> Invited By </b-th>
                    <b-td class="text-truncate">
                        <user-avatar :user="fields.inviteUser" />
                    </b-td>
                </b-tr>
                <b-tr v-if="user.cityName">
                    <b-th class="bg-light"> City </b-th>
                    <b-td class="text-truncate" style="max-width: 0">
                        {{ user.cityName }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.countryName">
                    <b-th class="bg-light"> Country </b-th>
                    <b-td class="text-truncate" style="max-width: 0">
                        <img :src="user.flagUrl" />
                        {{ user.countryName }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.ipLocation?.countryName">
                    <b-th class="bg-light"> IP Country </b-th>
                    <b-td class="text-truncate" style="max-width: 0">
                        <img :src="user.ipLocation.flagUrl" />
                        {{ user.ipLocation.countryName }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.mostRecentNearbyGpsCity?.countryName">
                    <b-th class="bg-light"> GPS Country </b-th>
                    <b-td class="text-truncate" style="max-width: 0">
                        <img :src="user.mostRecentNearbyGpsCity.flagUrl" />
                        {{ user.mostRecentNearbyGpsCity.countryName }}
                    </b-td>
                </b-tr>

                <user-field-gps />

                <b-tr v-if="user.languages">
                    <b-th class="bg-light"> Languages </b-th>
                    <b-td>
                        {{ user.languages }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.timezone">
                    <b-th class="bg-light"> Timezone </b-th>
                    <b-td>
                        {{ user.timezone }}
                    </b-td>
                </b-tr>
                <b-tr>
                    <b-th style="width: 20%" class="bg-light"> Created </b-th>
                    <b-td>
                        <live-timestamp :timestamp="user.model.created" />
                    </b-td>
                </b-tr>
                <b-tr v-if="user.age">
                    <b-th style="width: 20%" class="bg-light"> Age </b-th>

                    <b-td>
                        {{ user.age }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.landing">
                    <b-th style="width: 20%" class="bg-light"> Landing </b-th>

                    <b-td>
                        {{ user.landing }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.source">
                    <b-th style="width: 20%" class="bg-light"> Source </b-th>

                    <b-td>
                        {{ user.source }}
                    </b-td>
                </b-tr>
                <b-tr v-if="user.keyword">
                    <b-th style="width: 20%" class="bg-light"> Keyword </b-th>

                    <b-td>
                        {{ user.keyword }}
                    </b-td>
                </b-tr>

                <user-field-attribution />

                <b-tr v-if="user.creditRefillTotal">
                    <b-th style="width: 20%" class="bg-light">
                        Total Refills
                    </b-th>
                    <b-td> ${{ user.creditRefillTotal }} </b-td>
                </b-tr>
                <b-tr v-if="fields.inviteEarnings">
                    <b-th style="width: 20%" class="bg-light">
                        Invite Earnings
                    </b-th>
                    <b-td> ${{ fields.inviteEarnings }} </b-td>
                </b-tr>
                <b-tr v-if="user.creatorRewardedInvites">
                    <b-th style="width: 20%" class="bg-light"> Rewards </b-th>

                    <b-td> {{ user.creatorRewardedInvites }} </b-td>
                </b-tr>
                <b-tr v-if="user.totalCreditsEarned">
                    <b-th style="width: 20%" class="bg-light"> Earnings </b-th>

                    <b-td>
                        <coin-badge :amount="user.totalCreditsEarned" />
                    </b-td>
                </b-tr>

                <user-field-notifications />

                <b-tr v-if="fields.latestReferer">
                    <b-th class="bg-light"> Referer </b-th>
                    <b-td class="text-truncate" style="max-width: 0">
                        {{ fields.latestReferer.referer }}
                    </b-td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <user-field-withdraw />
    </div>
</template>

<script>
import { mapState } from "vuex";

import UserFieldBrand from "@/components/user/field/UserFieldBrand";
import UserFieldNotifications from "@/components/user/field/UserFieldNotifications";
import UserFieldLastActive from "@/components/user/field/UserFieldLastActive";
import UserFieldGps from "@/components/user/field/UserFieldGps";
import UserFieldWithdraw from "@/components/user/field/UserFieldWithdraw";
import UserFieldAttribution from "@/components/user/field/UserFieldAttribution";

export default {
    computed: {
        ...mapState("userEdit", ["user", "fields"]),
        ...mapState("user", ["isRoot"]),
    },
    components: {
        UserFieldLastActive,
        UserFieldBrand,
        UserFieldAttribution,
        UserFieldNotifications,
        UserFieldGps,
        UserFieldWithdraw,
    },
};
</script>