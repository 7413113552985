<template>
    <div>
        <b-table-simple striped>
            <b-thead>
                <b-th> Success </b-th>
                <b-th> Amount </b-th>
                <b-th class="text-center"> AVS </b-th>
                <b-th class="text-center"> CVV </b-th>
                <b-th> Postcode </b-th>
                <b-th> Last 4 </b-th>
                <b-th> Screened </b-th>
                <b-th> Created </b-th>
            </b-thead>
            <b-tbody>
                <b-tr
                    :key="transaction.authorizeTransaction.id"
                    v-for="transaction in transactions"
                >
                    <td class="text-center">
                        <font-awesome-icon
                            v-if="transaction.authorizeTransaction.successful"
                            icon="fa-light fa-check-circle"
                            class="text-success"
                        />
                        <font-awesome-icon
                            v-else
                            icon="fa-solid fa-times"
                            class="text-danger"
                        />
                    </td>
                    <td>${{ transaction.authorizeTransaction.amount }}</td>
                    <td
                        :class="`text-center text-${transaction.authorizeTransaction.avsVariant}`"
                    >
                        {{ transaction.authorizeTransaction.avsCode }}
                    </td>
                    <td
                        :class="`text-center text-${transaction.authorizeTransaction.cvvVariant}`"
                    >
                        {{ transaction.authorizeTransaction.cvvCode }}
                    </td>
                    <td>
                        {{ transaction.authorizeTransaction.postalCode }}
                    </td>
                    <td>
                        {{ transaction.userCreditCard?.lastFour }}
                    </td>
                    <td>
                        <font-awesome-icon
                            v-if="transaction.authorizeTransaction.screened"
                            icon="fa-light fa-check"
                        />
                    </td>
                    <td>
                        <live-timestamp
                            :timestamp="
                                transaction.authorizeTransaction.created
                            "
                        />
                    </td>
                    <td>
                        <b-btn
                            variant="light"
                            size="sm"
                            :href="`/authorizeTransaction/view/${transaction.authorizeTransaction.id}`"
                        >
                            View
                        </b-btn>
                    </td>
                </b-tr>
            </b-tbody>
        </b-table-simple>

        <b-modal
            centered
            v-model="modal"
            title="Transaction Detail"
            hide-footer
        >
            <div v-if="authorizeTransaction">
                <authorize-transaction-edit
                    :authorizeTransaction="authorizeTransaction"
                />
            </div>
        </b-modal>
    </div>
</template>


<script>
import { mapState } from "vuex";

import AuthorizeTransactionEdit from "@/components/authorizeTransaction/AuthorizeTransactionEdit";

export default {
    data() {
        return {
            modal: false,
            authorizeTransaction: null,
            transactions: null,
        };
    },
    computed: {
        ...mapState("userEdit", ["user"]),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        viewTransaction(authorizeTransaction) {
            this.authorizeTransaction = authorizeTransaction;
            this.modal = true;
        },
        refresh() {
            this.api
                .post(`/user/${this.user.model.id}/authorizeTransactions`)
                .then((response) => {
                    this.transactions = response.data.transactions;
                });
        },
    },
    components: {
        AuthorizeTransactionEdit,
    },
};
</script>