import api from "@/service/api";
import router from "@/router";

const state = () => ({
    user: null,
    tabs: null,
    fields: null,
    userFines: null,
    notFound: false,
    relatedUsers: false,
    vettedBanModal: false,
})

const mutations = {
    reset(state) {
        state.user = null
        state.fields = null
        state.relatedUsers = null
        state.notFound = false
    },
    setVettedBanModal(state, value) {
        state.vettedBanModal = value;
    },
    setTabs(state, value) {
        state.tabs = value;
    },
    setUserFines(state, value) {
        state.userFines = value;
    },
    setUser(state, value) {
        state.user = value;
    },
    setFields(state, value) {
        state.fields = value;
    },
    setNotFound(state, value) {
        state.notFound = value;
    },
    setPrimaryPhoto(state, value) {
        state.primaryPhoto = value;
    },
    setRelatedUsers(state, value) {
        state.relatedUsers = value;
    },
}

const actions = {
    async refresh({ dispatch, state }) {
        if (!state.user) {
            return
        }

        dispatch('get', {
            id: state.user.model.id
        })
    },
    async get({ commit }, { id, clear } = {}) {
        if (clear) {
            commit('reset')
        }

        try {
            const response = await api.post(`/user/${id}/get`);

            commit('setUser', response.data.user)
            commit('setFields', response.data.fields)
            commit('setTabs', response.data.tabs)
            commit('setUserFines', response.data.userFines)
        } catch (error) {
            if (error?.response?.status === 404) {
                commit('setNotFound', true)
            }
        }
    },
    async ban({ state, commit, dispatch }) {
        if (!state.user) {
            console.error("Not editing any user currently, refresh")
            return
        }

        if (state.user.model.vetted) {
            // show special modal to remove and ban
            commit('setVettedBanModal', true);
            throw new Error('User is vetted');
        }

        await api.post(`/review/${state.user.model.id}/ban`);
        dispatch('refresh')
    },
    changeTab({ state }, { tab, route } = {}) {
        const query = {};

        if (route.query?.buyer) {
            query.buyer = 1;
        }

        if (route.query?.vetting) {
            query.vetting = 1;
        }

        if (route.query?.review) {
            query.review = 1;
        }

        if (route.query?.screen) {
            query.screen = 1;
        }

        const params = {
            userId: state.user.model.id,
        };

        if (tab) {
            params.tab = tab;
        }

        router.push({
            name: "userEdit",
            query,
            params,
        });
    },
}



export default {
    namespaced: true,
    state,
    actions,
    mutations
}
